// Hook odpowiedzialny za pobranie szczgółów zamówienia

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError, IStatus } from 'api/types';

export interface HistoryItem {
  date: string;
  hour: string;
  status_type: number;
  status_message: string;
}

// typ zwracanych danych
export interface IResponse {
  id: number;
  order_id: number;
  client_email: string;
  client_first_name: string;
  client_id: number;
  client_last_name: string;
  client_name: string;
  client_notes: string;
  client_phone: string;
  client_tax_number: string;
  client_address: {
    name: null | string;
    street: null | string;
    building: null | string;
    apartment: null | string;
    postal_code: null | string;
    city: null | string;
    state: null | string;
    country: null | string;
    country_code: null | string;
  };

  receiver_delivery_point: null;
  receiver_email: string;
  receiver_first_name: string;
  receiver_id: number;
  receiver_last_name: string;
  receiver_name: string;
  receiver_name_to_show: string;
  receiver_phone: string;
  receiver_address: {
    name: null | string;
    street: null | string;
    building: null | string;
    apartment: null | string;
    postal_code: null | string;
    city: null | string;
    state: null | string;
    country: null | string;
    country_code: null | string;
  };
  create_date: string;
  status_type: number;
  status_message: string;
  value_net: number;
  value_net_formatted: string;
  value_gross: number;
  value_gross_formatted: string;
  currency: string;
  status_history: HistoryItem[];
  status: IStatus;
  name: string;
  message?: string;
}

const getPanelOrder = (id: number): Promise<IResponse> => axios.get(`/panel/orders/${id}`);

export const useGetPanelOrder = (id: number, options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['order', id], () => getPanelOrder(id), options);
