import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { useGetPaymentsOptions, usePostOnlinePaymentInitialize } from 'api';
import { IOrderListItem, IPamentMethod } from 'api/types';

import { Modal, Radio, Button } from 'components/controls';

import styles from 'theme/components/containers/PaymentsOptions/PaymentsOptions.module.scss';

// typ danych wejściowych
interface Props {
  paymentStatus: IOrderListItem['status_payment_formatted'];
  id: number;
  provider: 'DOCUMENT';
}

const PaymentsOptions: FC<Props> = ({ paymentStatus, id, provider }) => {
  const { t } = useTranslation();

  // czy jest otwarty modal z płatnosciami
  const [isOpen, setOpen] = useState(false);

  // wbrana metoda płatności
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | undefined>(undefined);

  const { data: paymentOptionsData, refetch: refetchPaymentOptionData } = useGetPaymentsOptions(
    { provider, providerElementId: id },
    { onSuccess: () => setOpen(true), enabled: false }
  );

  //inicjalizacja metody płatności
  const { mutate: initializePayment, isLoading } = usePostOnlinePaymentInitialize({
    onSuccess: ({ data }) => {
      if (data.redirect_type === 'REDIRECT_URL') {
        window.location.replace(data.redirect_payload);
      }
    }
  });

  const renderPayButton = () => {
    if (!paymentStatus.show_button_pay) {
      return null;
    }

    return (
      <button className={styles.payButton} onClick={() => refetchPaymentOptionData()}>
        <Trans>Opłać</Trans>
      </button>
    );
  };

  const renderPaments = (item: IPamentMethod) => (
    <label
      key={item.id}
      className={classnames(styles.item, { [styles.active]: item.id === selectedPaymentMethod })}>
      <Radio
        checked={item.id === selectedPaymentMethod}
        onClick={() => setSelectedPaymentMethod(item.id)}
      />

      <div className={styles.content}>
        <div className={styles.iconWrapper}>
          <img className={styles.icon} src={item.image} alt="icon" />
        </div>
        <div className={styles.name}>{item.name}</div>
      </div>
      <div>{item.price_formatted_to_show}</div>
    </label>
  );

  const renderActions = () => (
    <div className={styles.actions}>
      <div className={styles.row}>
        <div className={styles.labelBold}>
          <Trans>Do zapłaty</Trans>:
        </div>
        <div className={styles.valueTotalNet}>
          {paymentOptionsData?.amount_formatted && (
            <span className={classnames(styles.oldPrice)}>
              <span>
                {paymentOptionsData?.amount_formatted} {paymentOptionsData?.currency}
              </span>{' '}
            </span>
          )}
        </div>
      </div>
      <Button
        disabled={isLoading || !selectedPaymentMethod}
        onClick={() =>
          initializePayment({
            context: 'DOCUMENT_PAYMENT',
            entity_type: 'DOCUMENT',
            entity_id: 1,
            payment_method_id: selectedPaymentMethod
          })
        }>
        <Trans>Opłać</Trans>
      </Button>
    </div>
  );

  return (
    <div>
      {renderPayButton()}
      {isOpen && (
        <Modal title={t('Metody płatności')} onClose={() => setOpen(false)}>
          <div className={classnames(styles.componentWrapper)}>
            {paymentOptionsData?.payment_methods.map((method) => renderPaments(method))}
          </div>
          {renderActions()}
        </Modal>
      )}
    </div>
  );
};

export default PaymentsOptions;
