// lista dostaw

import React from 'react';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';
import { ChevronRight } from 'react-bootstrap-icons';
import classnames from 'classnames';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useGetDashboardDeliveries, useGetDashboardDeliveriesSummary } from 'api';
import { IDashboardDeliveryListItem } from 'api/types';
import { Status, Table, Link } from 'components/controls';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';

const Deliveries = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // pobranie listy dostaw
  const { data: dashboardDeliveriesData } = useGetDashboardDeliveries({ limit: 10, page: 1 });

  // pobranie podsumowania listy dostaw
  const { data: dashboardDeliveriesSummaryData } = useGetDashboardDeliveriesSummary();

  return (
    <div
      className={classnames(styles.listWrapper, 'StylePath-Pages-Dashboard-Components-Deliveries')}>
      <div className={classnames(styles.listHeader, styles.violet)}>
        <Trans>Lista dostaw</Trans>
        <Link to="/dashboard/deliveries" className={styles.link}>
          <Trans>więcej</Trans> <ChevronRight />
        </Link>
      </div>

      <div className={styles.tableWrapper}>
        {isMobile ? (
          <div className={styles.mobileList}>
            {dashboardDeliveriesData?.items.map((item) => (
              <div key={item.id} className={styles.mobileListItem}>
                <div className={styles.itemHeader}>
                  <Trans>Symbol</Trans>: {item.symbol}
                </div>
                <div className={styles.details}>
                  <div>
                    <span className={styles.label}>
                      <Trans>Data wysyłki</Trans>
                    </span>
                    <span>{format(new Date(item.delivery_date), 'dd-MM-yyyy')}</span>
                  </div>
                  <div>
                    <Status
                      icon={item.status.icon}
                      message={item.status.label}
                      color={item.status.color}
                    />
                  </div>
                  <div>
                    <span className={styles.label}>
                      <Trans>Sposób dostawy</Trans>
                    </span>
                    <span>{item.delivery_method_name}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Table<IDashboardDeliveryListItem>
            columns={[
              {
                title: <Trans>Symbol</Trans>,
                dataIndex: 'symbol',
                align: 'left',
                renderCell: (item) => <span className={styles.primaryText}>{item.symbol}</span>
              },
              {
                title: <Trans>Data wysyłki</Trans>,
                dataIndex: 'delivery_date',
                align: 'left',
                renderCell: (item) => format(new Date(item.delivery_date), 'dd-MM-yyyy')
              },
              {
                title: <Trans>Sposób dostawy</Trans>,
                dataIndex: 'delivery_method_name',
                align: 'left',
                renderCell: (item) => item.delivery_method_name
              },
              {
                title: <Trans>Nr listu przewozowego</Trans>,
                dataIndex: 'tracking_number',
                align: 'left',
                renderCell: (item) => item.tracking_number
              },
              {
                title: <Trans>Status</Trans>,
                dataIndex: 'status',
                align: 'right',
                renderCell: (item) => (
                  <Status
                    icon={item.status.icon}
                    message={item.status.label}
                    color={item.status.color}
                  />
                )
              }
            ]}
            dataSource={dashboardDeliveriesData?.items || []}
            rowKey="id"
          />
        )}
      </div>

      <div className={styles.listFooter}>
        <div className={styles.summary}>
          <div className={styles.title}>
            <Trans>Podsumowanie dostaw</Trans>
          </div>
          <div className={styles.items}>
            <div>
              <span>
                <Trans>W drodze</Trans>:
              </span>{' '}
              {dashboardDeliveriesSummaryData?.inway.count}
            </div>
            <div>
              <span>
                <Trans>Do opłacenia</Trans>:
              </span>{' '}
              {dashboardDeliveriesSummaryData?.topay.count}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliveries;
