// Hook odpowiedzialny za pobranie informacji o płatności

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// parametry requestu do api
interface IRequest {
  provider: 'DOCUMENT';
  providerElementId: number;
}

export type IPamentMethod = {
  id: number;
  name: string;
  image: string;
  price_net: number;
  price_gross: number;
  price_formatted_to_show: string;
  price_net_formatted: string;
  price_gross_formatted: string;
  commission: number;
  limit_comment: string;
  cash_on_delivery_editable: boolean;
  payment_type: string;
};

// typ zwracanych danych
export interface IResponse {
  payment_methods: IPamentMethod[];
  show_payment_methods_without_price: boolean;
  amount: number;
  amount_formatted: string;
  currency: string;
  entity_type: string;
  entity_id: number;
}

const getPaymentsOptions = (params?: IRequest): Promise<IResponse> =>
  axios.get('/payments/options', { params });

export const useGetPaymentsOptions = (
  params?: IRequest,
  options?: UseQueryOptions<IResponse, IError>
) =>
  useQuery<IResponse, IError>(
    [`payments-options-${params?.providerElementId}`, params],
    () => getPaymentsOptions(params),
    options
  );
