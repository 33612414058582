// boxy z podsumowaniem

import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';

import { useGetDashboardSummary } from 'api';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';

const Summary = () => {
  // pobranie danych z API
  const { data: dashboardSummaryData } = useGetDashboardSummary();

  return (
    <div className={classnames(styles.summary, 'StylePath-Pages-Dashboard-Components-Summary')}>
      <div className={styles.box}>
        <Trans>Przeterminowane płatności</Trans>
        <span className={classnames(styles.value, styles.red)}>
          {dashboardSummaryData?.payments_expired?.count}
        </span>
      </div>
      <div className={styles.box}>
        <Trans>Suma przeterminowanych płatności</Trans>
        <span className={styles.value}>
          {dashboardSummaryData?.payments_expired?.amount_formatted}{' '}
          {dashboardSummaryData?.payments_expired?.currency}
        </span>
      </div>
      <div className={styles.box}>
        <Trans>Limit kupiecki</Trans>
        <div>
          <div className={styles.value}>
            {dashboardSummaryData && (
              <div className={styles.progress}>
                <div
                  style={{
                    height:
                      ((dashboardSummaryData.trade_credit.trade_credit_free -
                        dashboardSummaryData.trade_credit.trade_credit_total) /
                        dashboardSummaryData.trade_credit.trade_credit_free) *
                      100,
                    backgroundColor: '#CCCFD5'
                  }}
                  className={styles.empty}></div>
                <div
                  style={{
                    height:
                      (dashboardSummaryData.trade_credit.trade_credit_total /
                        dashboardSummaryData.trade_credit.trade_credit_free) *
                      100,
                    backgroundColor: '#44D038'
                  }}
                  className={styles.fill}></div>
              </div>
            )}

            {dashboardSummaryData && (
              <span>
                {dashboardSummaryData?.trade_credit.trade_credit_total_formatted}{' '}
                {dashboardSummaryData.trade_credit.currency}/{' '}
                {dashboardSummaryData?.trade_credit.trade_credit_free_formatted}{' '}
                {dashboardSummaryData.trade_credit.currency}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
