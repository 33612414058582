// Hook odpowiedzialny za pobranie danych do podsumowaniu dashboardu

import axios from 'api/axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { ICommandResponseError as IError } from 'api/types';

// typ zwracanych danych
export interface IResponse {
  payments_expired: {
    amount: number;
    amount_formatted: string;
    currency: string;
    count: number;
  };
  payments_upcoming: {
    amount: number;
    amount_formatted: string;
    currency: string;
    count: number;
  };
  orders_in_progress: {
    amount: number;
    amount_formatted: string;
    currency: string;
    count: number;
  };
  orders_hold: {
    amount: number;
    amount_formatted: string;
    currency: string;
    count: number;
  };
  trade_credit: {
    trade_credit_total: number;
    trade_credit_total_formatted: string;
    trade_credit_free: number;
    trade_credit_free_formatted: string;
    currency: string;
  };
}

const getDashboardSummary = (): Promise<IResponse> => axios.get('dashboards/summary');

export const useGetDashboardSummary = (options?: UseQueryOptions<IResponse, IError>) =>
  useQuery<IResponse, IError>(['dashboard-summary'], getDashboardSummary, options);
