// lista zamówień

import React from 'react';
import { Trans } from 'react-i18next';
import { ChevronRight } from 'react-bootstrap-icons';
import { format } from 'date-fns';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useGetDashboardOrders } from 'api';
import { IDashboardOrderListItem } from 'api/types';
import { Table, Status, Link } from 'components/controls';

import styles from 'theme/pages/Dashboard/Dashboard.module.scss';
import classnames from 'classnames';

const Orders = () => {
  // TODO custom hook z dwóch poniższych?
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // pobranie indormacja o zamówieniach
  const { data: dashboardOrdersData } = useGetDashboardOrders({ page: 1, limit: 10 });

  return (
    <div className={classnames(styles.listWrapper, 'StylePath-Pages-Dashboard-Components-Orders')}>
      <div className={classnames(styles.listHeader, styles.blue)}>
        <Trans>Lista zamówień</Trans>
        <Link to="/dashboard/orders" className={styles.link}>
          <Trans>więcej</Trans> <ChevronRight />
        </Link>
      </div>

      <div className={styles.tableWrapper}>
        {isMobile ? (
          <div className={styles.mobileList}>
            {dashboardOrdersData?.items.map((item) => (
              <div key={item.id} className={styles.mobileListItem}>
                <div className={styles.itemHeader}>
                  <Trans>Symbol</Trans>: {item.symbol}
                </div>
                <div className={styles.details}>
                  <div>
                    <span className={styles.label}>
                      <Trans>Data</Trans>
                    </span>
                    <span>{format(new Date(item.date_order), 'dd-MM-yyyy')}</span>
                  </div>
                  <div>
                    <Status
                      icon={item.status.icon}
                      message={item.status.label}
                      color={item.status.color}
                    />
                  </div>
                  <div>
                    <span className={styles.label}>
                      <Trans>Wartość</Trans>:
                    </span>
                    <div></div>
                    <span>
                      {item.value_net_formatted} {item.currency} <Trans>netto</Trans>
                    </span>
                    <span>
                      {item.value_gross_formatted} {item.currency} <Trans>brutto</Trans>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Table<IDashboardOrderListItem>
            columns={[
              {
                title: <Trans>Symbol</Trans>,
                dataIndex: 'symbol',
                align: 'left',
                renderCell: (item) => <span className="promaryText">{item.symbol}</span>
              },
              {
                title: <Trans>Data</Trans>,
                dataIndex: 'date_order',
                align: 'right',
                renderCell: (item) => format(new Date(item.date_order), 'dd-MM-yyyy')
              },
              {
                title: <Trans>Status</Trans>,
                dataIndex: 'status',
                align: 'right',
                renderCell: (item) => (
                  <Status
                    icon={item.status.icon}
                    message={item.status.label}
                    color={item.status.color}
                  />
                )
              },
              {
                title: <Trans>Wartość netto</Trans>,
                dataIndex: 'value_net_formatted',
                align: 'right',
                renderCell: (item) => `${item.value_net_formatted} ${item.currency}`
              },
              {
                title: <Trans>Wartość brutto</Trans>,
                dataIndex: 'value_gross_formatted',
                align: 'right',
                renderCell: (item) => `${item.value_gross_formatted} ${item.currency}`
              }
            ]}
            dataSource={dashboardOrdersData?.items || []}
            rowKey="symbol"
          />
        )}
      </div>
    </div>
  );
};

export default Orders;
